<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" style="height: 25px" />
    <el-backtop target=".el-main"></el-backtop>

    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 220px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
      <el-input
        placeholder="请输入要查询的钱包地址"
        v-model="form.walletUrl"
      ></el-input>
      <el-date-picker
        v-model="form.date"
        type="date"
        placeholder="选择日期"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>

      <div style="display: flex; align-items: center; margin-top: 5px">
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
        <el-button
          plain
          @click="toggleSelection(tableData)"
          v-if="this.form.status == '1'"
          >全 选</el-button
        >
        <el-button @click="handleBatchBtn()" v-if="this.form.status == '1'"
          >批 量</el-button
        >
        <el-button @click="exportToExcel" v-if="this.form.status == '1'"
          >导 出</el-button
        >

        <el-button @click="handleBans" style="margin-right: 40px"
          >批量封禁</el-button
        >

        <el-switch
          v-if="isUserAuto"
          style="display: block"
          v-model="isAuto"
          active-text="自动上链"
          inactive-text="非自动上链"
          @change="handleAuto"
        >
        </el-switch>
      </div>
    </div>

    <div class="table_con" style="height: calc(100vh - 200px)">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        ref="multipleTable"
        tooltip-effect="dark"
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange"
        id="mytable"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="130"> </el-table-column>
        <el-table-column prop="userName" label="姓名" width="130">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="160">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>

        <el-table-column prop="cost" label="手续费"> </el-table-column>
        <el-table-column prop="wallet" label="转账地址" width="230">
        </el-table-column>
        <el-table-column prop="cple" label="CPLE转出金额" width="130">
        </el-table-column>
        <el-table-column prop="submitTime" label="提交转出时间" width="190">
        </el-table-column>
        <el-table-column prop="examineStatus" label="状态"> </el-table-column>

        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <div class="operation" v-if="scope.row.examineStatus == '审核成功'">
              审核成功
            </div>
            <div
              class="operation"
              v-else-if="scope.row.examineStatus == '待审核'"
            >
              <el-button
                type="text"
                size="small"
                @click="handleCPLEexamine(scope.row, 1)"
                >审核通过</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleCPLEexamine(scope.row, 2)"
                >审核拒绝</el-button
              >
            </div>
            <div
              class="operation"
              v-else-if="scope.row.examineStatus == '审核失败'"
            >
              审核失败
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import * as xlsx from "xlsx";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },

  inject: ["reload"],
  data() {
    return {
      form: {
        status: "1",
        phone: "",
        date: "",
        walletUrl: "",
      },
      tableData: [],
      multipleSelection: [],

      valueArea: "",
      options: [],

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "CPLE积分上链审核",
        },
        {
          name: "待审核列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200, 500],

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      isUserAuto: true,
      isAuto: true,
    };
  },

  mounted() {
    if (localStorage.userName == "13436969806") {
      this.isUserAuto = true;
    } else if (localStorage.userName !== "13436969806") {
      this.isUserAuto = false;
    }

    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getCpleUpperChainList,
      method: "POST",
      params: JSON.stringify({
        examineStatus: "1",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        submitTime: "",
        token: "",
        wallet: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);

    const optAuto = {
      url: reqUrl.getUpperChainOnOff,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        this.isAuto = res.data.data;

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optAuto);
  },

  methods: {
    searchList() {
      if (this.form.phone || this.form.date || this.form.walletUrl) {
        showLoading();
        const opt = {
          url: reqUrl.getCpleUpperChainList,
          method: "POST",
          params: JSON.stringify({
            examineStatus: "1",
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.form.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            submitTime: this.form.date,
            token: "",
            wallet: this.form.walletUrl,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              res.data.data.list.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
            console.log(err, "err1");
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 开启自动提现
    handleAuto() {
      this.isAuto = !this.isAuto; //保持switch点击前的状态

      this.$confirm("您确定要进行此操作吗，如确定，请继续", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const optAuto = {
            url: reqUrl.setUpperChainOnOff,
            method: "POST",
            params: JSON.stringify({
              onOff: !this.isAuto,
            }),
            resFunc: (res) => {
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
              if (res.data.code == 0) {
                this.refresh();
                this.$message.success("操作成功!");
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(optAuto);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getCpleUpperChainList,
        method: "POST",
        params: JSON.stringify({
          examineStatus: "1",
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.form.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          submitTime: this.form.date,
          token: "",
          wallet: this.form.walletUrl,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 全选
    toggleSelection(rows) {
      if (rows.length > 0) {
        let rowIdsSelected = [];
        rows.map((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
          rowIdsSelected.push(row.id);
        });

        this.$confirm("您确定要执行此操作吗?", "提示", {
          confirmButtonText: "审核通过",
          cancelButtonText: "审核拒绝",
          type: "warning",
        })
          .then(() => {
            this.$prompt("请输入Txid", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.+$/,
              inputErrorMessage: "请输入Txid",
            })
              .then(({ value }) => {
                showLoading();
                const opt = {
                  url: reqUrl.cpleUpperChainExamine,
                  method: "POST",
                  params: JSON.stringify({
                    auditor: localStorage.getItem("userName"),
                    ids: rowIdsSelected,
                    result: "2", // 通过2，拒绝3
                    txid: value,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                      hideLoading();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    hideLoading();
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$confirm("您确定要执行此操作吗?", "提示", {
                distinguishCancelAndClose: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  const opt = {
                    url: reqUrl.cpleUpperChainExamine,
                    method: "POST",
                    params: JSON.stringify({
                      auditor: localStorage.getItem("userName"),
                      ids: rowIdsSelected,
                      result: "3", // 通过2，拒绝3
                      txid: "",
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                        hideLoading();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                      hideLoading();
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      hideLoading();
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    // 批量
    handleBatchBtn() {
      if (this.multipleSelection.length > 0) {
        let batchIds = [];
        this.multipleSelection.map((item) => {
          batchIds.push(item.id);
        });
        this.$confirm("您确定要执行此操作吗?", "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "审核通过",
          cancelButtonText: "审核拒绝",
          type: "warning",
        })
          .then(() => {
            this.$prompt("请输入Txid", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.+$/,
              inputErrorMessage: "请输入Txid",
            })
              .then(({ value }) => {
                showLoading();
                const opt = {
                  url: reqUrl.cpleUpperChainExamine,
                  method: "POST",
                  params: JSON.stringify({
                    auditor: localStorage.getItem("userName"),
                    ids: batchIds,
                    result: "2", // 通过2，拒绝3
                    txid: value,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                      hideLoading();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    hideLoading();
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$prompt("请输入拒绝原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^.+$/,
                inputErrorMessage: "请输入拒绝原因",
              })
                .then(({ value }) => {
                  const opt = {
                    url: reqUrl.cpleUpperChainExamine,
                    method: "POST",
                    params: JSON.stringify({
                      auditor: localStorage.getItem("userName"),
                      rejectReason: value,
                      ids: batchIds,
                      result: "3", // 通过2，拒绝3
                      txid: "",
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                        hideLoading();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                      hideLoading();
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      hideLoading();
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    // 批量封禁
    handleBans() {
      if (this.multipleSelection.length > 0) {
        let banIds = [];
        this.multipleSelection.map((item) => {
          banIds.push(item.id);
        });
        this.$prompt("请输入封禁原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputErrorMessage: "请输入封禁原因",
        }).then(({ value }) => {
          const opt = {
            url: reqUrl.banUserBatchCpleChain,
            method: "POST",
            params: JSON.stringify({
              auditor: localStorage.getItem("userName"),
              ids: banIds,
              banReason: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.refresh();
                hideLoading();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        });
      } else {
        this.$notify.error({
          title: "错误",
          message: "请至少选择一项哦",
        });
      }
    },

    exportToExcel() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: "请先选择你要导出的数据哦",
          type: "warning",
        });
        return;
      }

      const arr = this.multipleSelection.map((item, index) => {
        return {
          序号: index + 1,
          ID: item.id,
          姓名: item.userName,
          手机号: item.phone,
          状态: item.examineStatus,
          手续费: item.cost,
          转账地址: item.wallet,
          CPLE转出金额: item.cple,
          提交转出时间: item.submitTime,
        };
      });
      const sheet = xlsx.utils.json_to_sheet(arr);
      const book = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(book, sheet, "sheet1");
      xlsx.writeFile(book, "CPLE积分上链审核列表.xlsx");
    },

    handleCPLEexamine(row, index) {
      let idsValue = [];
      idsValue.push(row.id);

      if (index == 1) {
        this.$prompt("请输入Txid", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputErrorMessage: "请输入Txid",
        })
          .then(({ value }) => {
            showLoading();
            const opt = {
              url: reqUrl.cpleUpperChainExamine,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                ids: idsValue,
                result: "2", // 通过2，拒绝3
                txid: value,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                  hideLoading();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      } else if (index == 2) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            const opt = {
              url: reqUrl.cpleUpperChainExamine,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                ids: idsValue,
                result: "3", // 通过2，拒绝3
                txid: "",
                rejectReason: value,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                  hideLoading();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      }
    },

    changeItem(item) {
      if (item.examineStatus == 1) {
        item.examineStatus = "待审核";
      } else if (item.examineStatus == 2) {
        item.examineStatus = "审核成功";
      } else if (item.examineStatus == 3) {
        item.examineStatus = "审核失败";
      }

      item.submitTime = format_time_date(item.submitTime);
    },

    reset() {
      if (this.form.phone || this.form.date || this.form.walletUrl) {
        this.form.phone = "";
        this.form.date = "";
        this.form.walletUrl = "";

        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-wrap: wrap;

  .el-input {
    width: 200px;
    margin-right: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
</style>
